import React from 'react'
import {Row, Container, Col, Button} from 'react-bootstrap'
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import heroStyles from './Hero.module.css'

const Hero = ({ctaClick}) => {
    const data = useStaticQuery(graphql`
      query {
        heroImage: contentfulWebsiteImages(imageId: { eq: "indexHeroImage" }) {
          image {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    `)

  return (
    <div className={heroStyles.background}>
      <Container>
        <Row className={heroStyles.row}>
          <Col xl="6">
            <div className={heroStyles.titleSection}>
              <h1 className={heroStyles.title}>
                Middleware for the energy transition
              </h1>
              <p className={heroStyles.subtitle}>
                GridFabric creates OpenADR software solutions that help power
                utilities and IoT products to shed, shift, shimmy and shape peak
                load.
              </p>
              <div className={heroStyles.ctaSection}>
                <div className={heroStyles.ctaButton}>
                  <Button className="btn-gridfabric btn-lg" onClick={ctaClick}>
                    Contact Us
                  </Button>
                </div>
              </div>
            </div>
            <div className="d-block d-xl-none" style={{ padding: "30px 0" }}>
              <Img
                fluid={data.heroImage.image.fluid}
                alt="Hero"
                className={heroStyles.heroImageStyle}
              />
            </div>
          </Col>
          <Col className="d-none d-xl-block" style={{ padding: "0" }}>
            <Img
              fluid={data.heroImage.image.fluid}
              alt="Hero"
              className={heroStyles.heroImageStyle}
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Hero

