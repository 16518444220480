import React from "react"
import {Container} from 'react-bootstrap'
import { Link, graphql } from "gatsby"
import { createScrollingRef } from "../utils/scrollHooks"

import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import Hero from '../components/Hero/Hero'
import HomepageProducts from '../components/HomepageProducts/HomepageProducts'
import Contact from '../components/Contact/Contact'
import {SectionLeft, SectionRight} from '../components/Section/Section'
import { clickEvent } from "../utils/events"


const IndexPage = props => {
  
  const [contact, scrollToContact] = createScrollingRef("Index Page", "Index Contact")
  return (
    <Layout>
      <SEO title="Home" />
      <Hero ctaClick={scrollToContact} />
      <HomepageProducts />
      <div>
        <Container style={{ padding: "70px 5px", textAlign: "center" }}>
          <h2 className="header-text">Why load shifting?</h2>
          <p className="subheader-text">
            With renewable energy, <strong>when</strong> you use electricity is
            becoming more important than <strong>how much</strong> you use
          </p>
          <SectionRight
            image={props.data.renewables.image.fluid}
            style={{ color: "darkgray" }}
          >
            <h5>Renewables solve one problem but create another</h5>
            <p>
              Fortunately, renewable energy is edging out fossil fuels to
              produce cleaner, cheaper electricity. But wind and solar power
              production is naturally variable, so we must find ways to shift
              power consumption to align with generation.
            </p>
          </SectionRight>
          <SectionLeft
            image={props.data.loadShifting.image.fluid}
            style={{ color: "darkgray" }}
          >
            <h5>Smart devices help by shifting energy use</h5>
            <p>
              Smart devices reduce consumption when the supply is low, and
              increase consumption when there is excess. This increases the
              efficiency of the power grid, and means product owners, product
              manufactures, and grid operators all come out ahead.
            </p>
          </SectionLeft>
          <SectionRight
            image={props.data.smartHome.image.fluid}
            style={{ color: "darkgray" }}
          >
            <h5>GridFabric helps companies help the grid</h5>
            <p>
              Our products facilitate load shifting communication:{" "}
              <b>
                <Link
                  to="/canvas"
                  onClick={() => clickEvent("Index Page", "Index Canvas")}
                >
                  Canvas
                </Link>
              </b>{" "}
              is an OpenADR VTN server that utilities and load aggregators use
              to send load shifting signals, and{" "}
              <b>
                <Link
                  to="/plaid"
                  onClick={() => clickEvent("Index Page", "Index Plaid")}
                >
                  Plaid
                </Link>
              </b>{" "}
              is an OpenADR VEN client that helps owners and controllers of end
              devices parse and respond to them.
            </p>
          </SectionRight>
        </Container>
      </div>
      <div ref={contact} />
      <Contact header="Let's solve this together" />
    </Layout>
  )}

export default IndexPage

export const standardImage = graphql`
         fragment standardImage on ContentfulWebsiteImages {
           image {
             fluid {
               ...GatsbyContentfulFluid_withWebp
             }
           }
         }
       `

export const indexQuery = graphql`
         query {
           renewables: contentfulWebsiteImages(
             imageId: { eq: "renewablesSolve" }
           ) {
             ...standardImage
           }
           smartHome: contentfulWebsiteImages(
             imageId: { eq: "gridfabricHelpsGrid" }
           ) {
             ...standardImage
           }
           loadShifting: contentfulWebsiteImages(
             imageId: { eq: "smartDevicesShift" }
           ) {
             ...standardImage
           }
         }
       `
