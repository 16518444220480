import React from "react"
import { Row, Container, Col, Button } from "react-bootstrap"
import { Link, graphql, useStaticQuery } from "gatsby"

import styles from "./HomepageProducts.module.css"

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      canvasIcon: contentfulWebsiteImages(imageId: { eq: "canvasIconSVG" }) {
        image {
          file {
            url
          }
        }
      }
      plaidIcon: contentfulWebsiteImages(imageId: { eq: "plaidIconSVG" }) {
        image {
          file {
            url
          }
        }
      }
    }
  `)

  return (
    <div className={styles.background}>
      <Container className={styles.productsSection}>
        <Row className={styles.productsHeaderSection}>
          <h2 className={styles.productsHeader}>Our products</h2>
          <p className={styles.productsSubHeader}>
            Our software products make OpenADR easy, so companies of all types
            can start load shifting
          </p>
        </Row>
        <Row className={styles.productsExplainer}>
          <Col className={styles.productDisplay}>
            <div className={styles.productIcon}>
              <img
                src={data.canvasIcon.image.file.url}
                alt="Canvas Icon"
                style={{ marginBottom: "0" }}
              />
            </div>
            <div className={styles.productDisplayTitle}>Canvas</div>
            <p className={styles.productDisplaySubtitle}>
              OpenADR Server (VTN)
            </p>
            <p className={styles.productDisplayText}>
              Canvas implements a Virtual Top Node (VTN), the server side of the
              OpenADR protocol. It is used by Grid Operators and Aggregators to
              administrate load shifting programs.
            </p>
            <Link to="/canvas">
              <Button className="btn-gridfabric-outline">Learn More</Button>
            </Link>
          </Col>
          <Col className={styles.productDisplay}>
            <div className={styles.productIcon}>
              <img
                src={data.plaidIcon.image.file.url}
                alt="Plaid Icon"
                style={{ marginBottom: "0" }}
              />
            </div>
            <div
              className={styles.productDisplayTitle}
              style={{ color: "#4ad1ef" }}
            >
              Plaid
            </div>
            <p
              className={styles.productDisplaySubtitle}
              style={{ color: "#4ad1ef" }}
            >
              OpenADR Client (VEN)
            </p>
            <p className={styles.productDisplayText}>
              Plaid implements a Virtual End Node (VEN), the client side of the
              OpenADR protocol. It is used by controls and IoT product companies
              to participate in load shifting programs.
            </p>
            <Link to="/plaid">
              <Button className="btn-gridfabric-outline-secondary">
                Learn More
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Hero
